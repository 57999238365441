// eslint-disable-next-line no-restricted-imports
import {resolvePath, useFetcher as useRemixFetcher} from '@remix-run/react';
import {useEffect, useMemo, useRef} from 'react';
import {invalidateCache} from 'remix-client-cache';

import type {RecursivePartial, RestArguments} from '~/types';

import {urlParamsNormalizer} from '../utils/urls';

type UseFetcherParams = Parameters<typeof useRemixFetcher>;

/**
 * I won't pretend to know how this works, it was mainly made by Sergey Melnikov on slack. Thanks dood!
 */
type Widen<T> = T extends number
  ? number
  : T extends string
    ? string
    : T extends boolean
      ? boolean
      : RecursivePartial<T>;

/**
 * A hook that wraps Remix's useFetcher hook and augments the submit and load methods to return a promise that resolves when the fetcher is done.
 * This also enforces you to pass the correct types for the variables and data so we are more tightly coupled with our schemas.
 */
export function useFetcher<
  TSubmitTuple extends [unknown, unknown],
  TLoadData = unknown,
>(...args: UseFetcherParams) {
  const fetcher = useRemixFetcher<TSubmitTuple[0]>(...args);
  const pendingSubmitRef = useRef<{
    resolve: (data: TSubmitTuple[0]) => void;
  } | null>(null);
  const pendingLoadRef = useRef<{
    resolve: (data: TLoadData) => void;
  } | null>(null);

  const pendingSubmit = pendingSubmitRef.current;

  // useEffect is needed instead of useLayoutEffect because the fetchers can run during SSR. Invoking useLayoutEffect during SSR can cause errors.
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data && pendingSubmit) {
      pendingSubmit.resolve(fetcher.data as TSubmitTuple[0]);
      pendingSubmitRef.current = null;
    }
  }, [pendingSubmit, fetcher.data, fetcher.state]);

  const pendingLoad = pendingLoadRef.current;
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data && pendingLoad) {
      pendingLoad.resolve(fetcher.data as TLoadData);
      pendingLoadRef.current = null;
    }
  }, [pendingLoad, fetcher.data, fetcher.state]);

  const typedFetcher = useMemo(() => {
    return {
      ...fetcher,
      json: fetcher.json as TSubmitTuple[0] | undefined,
      submit: <TTarget extends TSubmitTuple[0]>(
        target: TTarget,
        ...args: RestArguments<Parameters<(typeof fetcher)['submit']>>
      ) => {
        // TODO - once errors are calmed down, we should probably log an error if we try to submit while we have a pending load - our hook doesn't handle that case.
        pendingLoadRef.current = null;
        fetcher.submit(target as any, ...args);
        return new Promise<Extract<TSubmitTuple, [Widen<TTarget>, unknown]>[1]>(
          (resolve) => {
            pendingSubmitRef.current = {resolve};
          },
        );
      },
      load: (
        url: string,
        ...args: RestArguments<Parameters<(typeof fetcher)['load']>>
      ) => {
        // load should ALWAYS invalidate the cache so we always get fresh data
        // this can't be used the same way as useCachedLoaderData because load is called once and then done, so it would always return cached data without this.
        if (typeof window !== 'undefined') {
          // Ignore the query params when creating the path for relative urls so the search params can be appended properly.
          // This is needed bc app-bridge keeps the auth query params in the iFrame location when the route is loaded directly instead of through a navigation link.
          const path = resolvePath(url, window.location.href.split('?')[0]);
          // This handles API loader endpoints that start with . (will have the full url in the pathname) instead of a partial route.
          const fullPath = path.pathname.startsWith('/')
            ? `${window.location.origin}${path.pathname}`
            : path.pathname;
          invalidateCache(
            urlParamsNormalizer(`${fullPath}${path.search}${path.hash}`),
          );
        }

        // TODO - once errors are calmed down, we should probably log an error if we try to load while we have a pending submit - our hook doesn't handle that case.
        pendingSubmitRef.current = null;

        fetcher.load(url, ...args);
        return new Promise<TLoadData>((resolve) => {
          pendingLoadRef.current = {resolve};
        });
      },
    };
  }, [fetcher]);

  return typedFetcher;
}
